import { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/nl';
import { subscribeEventAPI } from '../utils/config';
import { adjustTime } from '../helpers/helpers';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import FirstModal from '../components/FirstModal'
import NotificationIcon from '../assets/new-image/Notification.svg';
import NewImage from '../assets/new-image/Img.svg';
import NewsLetterImg from '../assets/image svg/newsletter.svg';

dayjs.extend(utc);
dayjs.extend(timezone);



const Carousel = ({ onlineEvents, token, formattedCurrentDate }) => {
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subscribeObj, setSubscribeObj] = useState({
    Email: '', event: '', event_id: '', token: ''
  });

  // const [subscribeObj, setSubscribeObj] = useState({
  //   Email: '', eventName: '', eventId: '', token: ''
  // });
  const [userToken, setUserToken] = useState('');

  const modalEmailInputRef = useRef(null);

  useEffect(() => {
    const tokenFromCookie = getCookie('token');
    setUserToken(tokenFromCookie);
  }, []);



  useEffect(() => {

    const closeOnOutsideClick = (e) => {
      console.log(e.target, 'target', firstModal)
      if (!e.target.closest('.modal-content')) {
        closeModalHandler()
      }
    }
    document.addEventListener('click', closeOnOutsideClick)
    return () => {
      document.removeEventListener('click', closeOnOutsideClick)

    }
  }, [])


  if (onlineEvents.length < 1) {
    return null
  }

  const lastIndex = onlineEvents.length - 1;

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  async function sendEmail(userObj) {
    try {
      const response = await fetch(`${subscribeEventAPI}`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userObj),
      });
      // const resData = await response.json();
      // const data = await resData;
      // console.log('sendEmailData:', data)
      return response
    } catch (err) {
      console.error('Error===>', err);
    }
  }

  const sortedByDate = onlineEvents
    .filter(event => {
      const registerFrom = adjustTime(event.RegistrationFrom)
      const registerUntil = adjustTime(event.RegistrationUntil)
      // console.log('formattedCurrentDate', formattedCurrentDate)
      // console.log('formattedCurrentDate RegistrationFrom', registerFrom)
      // console.log('formattedCurrentDate RegistrationUntil', registerUntil)

      return formattedCurrentDate < registerFrom;
    })
    .map(event => {
      const timestamp = parseInt(event.EventDate);
      const dateObject = dayjs.unix(timestamp).utc().locale('nl');

      return {
        ...event,
        fulldate: {
          date: dateObject.format('D MMM'),
          time: dateObject.format('HH:mm')
        },
        timestamp
      };
    });

  sortedByDate.sort((a, b) => a.timestamp - b.timestamp);
  const modalEmailInput = document.getElementById('modalEmailInput');

  const getEventData = (eventItem) => {
    const eventName = eventItem.EvenName;
    const eventId = eventItem.EventId;
    setSubscribeObj(prevState => ({
      ...prevState,
      event: eventName,
      event_id: eventId,
      token: userToken
    }));
  }



  const openFirstModal = (e, item) => {
    e.stopPropagation();
    // if (e.target.closest('.swiper-button-next') || e.target.closest('.swiper-button-prev')) return
    getEventData(item);
    setFirstModal(true);
  };

  const validateEmail = async () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(modalEmailInput.value);
    if (isValidEmail) {
      const updatedSubscribeObj = {
        ...subscribeObj,
        Email: modalEmailInputRef.current.value,
      };
      // console.log('updatedSubscribeObj', updatedSubscribeObj)
      setSubscribeObj(updatedSubscribeObj);

      // setTimeout(() => {
      const response = await sendEmail(updatedSubscribeObj)
      // const response = await sendEmail(updatedSubscribeObj)
      if (response.status === 200) {
        setEmailError(false);
        closeModalHandler()
        setSecondModal(true);
      }



    } else {
      setEmailError(true);
    }
  }

  const closeModalHandler = () => {
    console.log('closeModalHandler')
    if( modalEmailInputRef.current){
      modalEmailInputRef.current.value = '';
    }
    setFirstModal(false);
    setEmailError(false);
    setSubscribeObj({
      email: '',
      eventName: '',
      eventId: '',
      token: token
    });
  }



  console.log('onlineEvents.length', onlineEvents.length)
  return (
    <div className={onlineEvents.length > 2 ? 'custom-container slides-group' : 'custom-container '}>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        breakpoints={{
          // when window width is >= 640px
          340: {
            // width: 640,
            slidesPerView: 1.5,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2.6,
          },
        }}
        modules={[Navigation, Scrollbar, A11y]}
        // slidesPerView={'2.5'}
        navigation={onlineEvents.length > 2 ? true : false}
        pagination={{
          clickable: true,
        }}
        scrollbar={{ draggable: true }}
        isDuplicate={onlineEvents.length > 2 ? true : false}
        // onSlideChange={(swiper) => disableSwiperBtns(swiper.activeIndex)}
        className="custom-arrow"
       // spaceBetween={10}
      >
        {sortedByDate.map((item, index) => (

          <SwiperSlide key={index}>
            <div className="slider-item">
              {/* <img src={item.EvenementImages} alt="Image 1" /> */}
              <img src={item.onlineSoonImage} alt="Event image" className="slider-event-img" />
              <button
                className="openFirstModal btn trigger"
                onClick={(e) => openFirstModal(e, item)}
              >
                <img
                  src={NotificationIcon}
                  alt="notification"
                  className="Notification-img"
                />
              </button>
              <div className="section-text">
                <h5 className="slider-heading">
                  {/* Zomer w/ Colyn 10HRS */}
                  {/* {item.EvenName} */}
                  {item.EvenName.charAt(0).toUpperCase() + item.EvenName.slice(1).toLowerCase().substring(0, 24)}
				   {item.EvenName.length > 24 ? '...' : ''}
                </h5>
                <p className="slider-p">
                  {/* Thuishaven,Amsterdam */}
                  {item.TheClub.charAt(0).toUpperCase() + item.TheClub.slice(1).toLowerCase()}, {item.CityName}
                </p>
                <div className="toolt">
                  <span>{item.fulldate.date} — {item.fulldate.time}</span>
                  <span className="ml-1.5">{item.MinimalAge}+</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        )
        )}

      </Swiper>
      {/* First Modal */}
      <div className={clsx('modal firstModal', firstModal ? 'open' : '')} id="">
        <div className="modal-content">
          <div className="header">
            <span
              className="closeSecondModal close-button"
              onClick={closeModalHandler}
            >
              &times;
            </span>
            <h2>Hou me op de hoogte</h2>
          </div>
          <div className="Modal-Body">
            <p>Jouw gegevens</p>
            <input
              ref={modalEmailInputRef}
              id="modalEmailInput"
              type="email"
              name="email"
              // value={modalEmailValue}
              placeholder="E-mail"
            />
            {emailError ? <p className="modal-error">Geen geldig e-mailadres. Probeer het opnieuw.</p> : <p className="sturen">
              We sturen jou een e-mail zodra het event live is!
            </p>}
            {/* <p className="sturen">
              We sturen jou een e-mail zodra het event live is!
            </p> */}
            <button
              className="openSecondModal Stuur"
              type="button"
              onClick={(e) => validateEmail()}
            >
              Stuur mij een e-mail
            </button>
          </div>
        </div>
      </div>
      {/* Second Modal */}
      <div
        className={clsx('modal secondModal', secondModal ? 'open' : '')}
        id=""
      >
        <div className="modal-content">
          <div className="header">
            <span
              className="closeSecondModal close-button"
              onClick={() => setSecondModal(false)}
            >
              &times;
            </span>
            <h2>Hou me op de hoogte</h2>
          </div>
          <div className="Modal-Body">
            <div className="thnaks-img flex justify-center">
              <img src={NewsLetterImg} alt="newletter" className="thanks" />
            </div>
            <p className="gelukt">
              Gelukt! We sturen jou een e-mail zodra het event live is!
            </p>
            <button
              className="openSecondModal Stuur"
              type="submit"
              onClick={() => setSecondModal(false)}
            >
              Terug naar home
            </button>
          </div>
        </div>
      </div>{' '}
    </div>
  );
};

export default Carousel;
