import DatumIcon from '../assets/image svg/Calendar-N.svg';
import CategoryIcon from '../assets/image svg/party.svg';
import GenreIcon from '../assets/image svg/Music.svg';
import UserIcon from '../assets/image svg/User.svg';

import ArrowDownIcon from '../assets/image svg/chevron down.svg';
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const discoveryMenus = {
  datum: [
    'Vandaag',
    'Morgen',
    'Deze week',
    'Volgende week',
    'Deze maand',
    'Alle datums',
  ],
  categorie: ['Dag event', 'Nacht event', 'Festival', 'Alle events'],
  genre: [
    'Amapiano',
    'Classics',
    'Hip-hop',
    'Hitjes',
    'House',
    'Disco',
    'Techno',
    'RnB'
  ],
  leeftijd: ['18+', '21+', '23+', 'Alle leeftijden'],
};

const DiscoverEvent = ({sortState, updateSortState, city, genres}) => {
  const [discovery, setDiscovery] = useState(sortState);
  // const genreList = discoveryMenus.genre;
  console.log('DiscoverEvent', genres)
  const genreList = genres;
  const [selectedGenres, setSelectedGenres] = useState([...genres]);
  const [filtersNames, setFiltersNames] = useState({
    datum: 'Datum', 
    categorie: 'Categorie',
    leeftijd: 'Leeftijd'
  });

  console.log('genreList_selected',genreList,selectedGenres)

  const updateFiltersName = (filterName, filterText) => {
    setFiltersNames(prevNames => ({
      ...prevNames,
      [filterName]: filterText
    }));
  }

  useEffect(() => {
    console.log('Received genres:', genres);
    // Check if genres contains data, and if so, set it as the initial state
    if (genres && genres.length > 0) {
      setSelectedGenres([...genres]);
    }
  }, [genres]);
  
  const genreChange = (selectedItems) => {
    setSelectedGenres(selectedItems);
    clickHandler(selectedItems, 'genre')
    clickHandler(city, 'city')
  };

  const clickHandler = (item, category) => {
    setDiscovery(prevDiscovery => {
      const updatedDiscovery = { ...prevDiscovery, [category]: item };
      updateSortState(updatedDiscovery);
      return updatedDiscovery;
    });
  };

  useEffect(() => {
    // refreshes filters when city changes
    setDiscovery({
      datum: 'Alle Datums',
      categorie: 'Alle events',
      genre: [...genreList],
      leeftijd: 'Alle leeftijden',
      city: city,
    });
    setFiltersNames({
      datum: 'Datum',
      categorie: 'Categorie',
      leeftijd: 'Leeftijd',
    });
    setSelectedGenres([...genreList]);
  }, [city]);
  
  return (
    <div className="custom-container">
      <h2 className="text-white ontdek">Ontdek evenementen</h2>
      <div className="wrapper">
        <div className="external-dropdown">
          <div className="outer">
          {/* sort by dates */}
            <div className="side filter-date">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={DatumIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {/* {discovery.datum} */}
                    {/* Datum */}
                    {filtersNames.datum}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup
                    className=""
                    defaultValue="Alle datums"
                    type="radio"
                  >
                    {discoveryMenus.datum.map((item, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          console.log('item', item)
                          clickHandler(item, 'datum');
                          clickHandler(city, 'city');
                          updateFiltersName('datum', item)
                        }
                        }
                        value={item}
                      >
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
          {/* sort by categories */}
            <div className="side filter-category">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={CategoryIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {/* {discovery.categorie} */}
                    {/* Categorie */}
                    {filtersNames.categorie}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup
                    className=""
                    defaultValue="Alle events"
                    type="radio"
                  >
                    {discoveryMenus.categorie.map((item, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          // setDiscovery({ ...discovery, categorie: item })
                          clickHandler(item, 'categorie');
                          clickHandler(city, 'city');
                          updateFiltersName('categorie', item);
                        }
                        }
                        value={item}
                      >
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
            {/* sort by genres */}
            <div className="side filter-genre">
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={GenreIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {/* {discovery.genre[0]} */}
                    Genre
                  </span>
                </MenuButton>
                <MenuList
                  className="menu-list menu-checkbox-list"
                  pos="absolute"
                  top="8"
                  left="0"
                >
                {console.log('selectedGenres',selectedGenres)}
                  <MenuOptionGroup
                    className=""
                    type="checkbox"
                    // defaultValue={selectedGenres}
                    aria-multiselectable
                    value={selectedGenres}
                    onChange={(selectedItems) => genreChange(selectedItems)}
                  >
                    {genreList.map((item, index) => (
                      <MenuItemOption key={index} value={item}>
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
            {/* sort by age */}
            <div className="side filter-age">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={UserIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {/* {discovery.leeftijd} */}
                    {/* Leeftijd */}
                    {filtersNames.leeftijd}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup className="" defaultValue="Alle leeftijden" type="radio">
                    {discoveryMenus.leeftijd.map((item, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          // setDiscovery({ ...discovery, leeftijd: item })
                          clickHandler(item, 'leeftijd');
                          clickHandler(city, 'city');
                          updateFiltersName('leeftijd', item);
                        }
                        }
                        value={item}
                      >
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverEvent;
