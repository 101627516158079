import { useEffect, useState } from 'react';
import { genresApi } from '../utils/config';
import axios from 'axios';

function useGenres() {
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGenres = async () => {
    try {
      const response = await axios.post(genresApi);
      console.log('genres', response.data?.genrelist
      )
      const genres = response?.data?.genrelist?.map((genre) => (genre?.GName))

      setGenres(genres)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setGenres([])
      setLoading(false)
    }

  }

  useEffect(() => {
    handleGenres();
  }, []);

  return { genres, loading };
}

export default useGenres;