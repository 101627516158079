import { useEffect, useState } from 'react';
import clsx from 'clsx';
// import axios from 'axios';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import './Home.css';

import AmsterdamIcon from '../../assets/image svg/Amsterdam.svg';
import RotterdamIcon from '../../assets/image svg/Rotterdam.svg';
// import Cards from '../../components/Cards';
import DiscoverEvent from '../../components/DiscoverEvent';
import Carousel from '../../components/Carousel';
import { API_URL } from '../../utils/config';
import SearchBar from '../../components/SearchBar';
import EventsList from '../../components/EventsList';
import Logo from '../../components/Logo';
import { adjustTime } from '../../helpers/helpers';
import NoEvents from '../../components/NoEvents';
import EventItemPreloader from '../../components/EventItemPreloader';
// import eventItems from '../../utils/mockupData.json';
import useGenres from '../../hooks/useGenres';
dayjs.extend(utc);
dayjs.extend(timezone);

const Home = ({ token, eventData,genres,eventsLoading,setEventsLoading }) => {
  const [tab, setTab] = useState(1);
  const [onlineEvents, setOnlineEvents] = useState([]);
  const [sortedData, setSortedData] = useState([...eventData]);
  const [tabLoading, setTabLoading] = useState(false);
  const [sortState, setSortState] = useState({
    datum: 'Alle Datums',
    categorie: 'Alle events',
    genre: [
      ...genres
    ],
    leeftijd: 'Alle leeftijden',
    city: 'Amsterdam'
  });

  const [currentCity, setCurrentCity] = useState('Amsterdam');

  const [currentNetherlandsTime, setCurrentNetherlandsTime] = useState(() => {
    const netherlandsTime = new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Amsterdam',
    });
    return netherlandsTime;
  });
  const formattedCurrentDate = dayjs(currentNetherlandsTime).format('YYYY-MM-DD HH:mm:ss');
  
  const updateLocalStorage = (keyName, items) => {
    localStorage.setItem(keyName, JSON.stringify(items));
  }

  const updateSortState = (newState) => {
    setSortState(newState);
  };

  useEffect(() => {
    console.log('Child component mounted');
    return () => {
      console.log('Child component unmounted');
    };
  }, []);

  console.log('sortStatetab',tab)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentNetherlandsTime(() => {
        const netherlandsTime = new Date().toLocaleString('en-US', {
          timeZone: 'Europe/Amsterdam',
        });
        return netherlandsTime;
      }); // Update current date and time
    }, 1000); // Update every second (adjust as needed)

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, []);

  
  useEffect(() => {
    updateLocalStorage('allEvents', eventData);
    // setOnlineEvents(eventData.filter(event => event.OnlineSoon === "Yes" && event.CityName === currentCity));
  }, [eventData]);

  useEffect(() => {
    console.log('sortState_changed:', sortState, eventData);
    sortAll()
    if(eventData.length > 0) {
      setEventsLoading(false);
    }
  }, [eventData, sortState]);


  

  // console.log(sortState,'sortState')

  const sortAll = () => {
    // console.log('sortAll called');
    const sortedByDates = sortByDates(eventData);
    // console.log('sortedByDates:', sortedByDates);
    const sortedByCategory = sortByCategory(sortedByDates);
    // console.log('BY Category:', sortedByCategory)
    const sortedByGenres = sortByGenres(sortedByCategory);
    // console.log('sortedByGenres:', sortedByGenres)  
    const sortedByAge = sortByAge(sortedByGenres);
    // console.log('sortedByAge:', sortedByAge)
    const sortedByCity = sortByCity(sortedByAge)
    // console.log('SORTED:', sortedByCity)
    setSortedData(sortedByCity);
    setOnlineEvents(sortedByCity.filter(event => formattedCurrentDate < adjustTime(event.RegistrationFrom) && event.CityName === currentCity));
    console.log('SORTED_DATA:', sortedData);
    console.log('SORTED_DATA_ONLINE:', onlineEvents);
  }

  const sortByDates = (arr) => {
    let sortedByDates = [];

    const sortStatus = sortState['datum'].toLocaleLowerCase();
    const today = dayjs().utc().locale('nl');
    const tomorrow = today.add(1, 'day');
    const startOfWeek = today.startOf('week');
    const endOfWeek = today.endOf('week');
    const startOfNextWeek = today.add(1, 'week').startOf('week');
    const endOfNextWeek = today.add(1, 'week').endOf('week');
    const startOfMonth = today.startOf('month');
    const endOfMonth = today.endOf('month');

    switch (sortStatus) {
      case 'vandaag':
        sortedByDates = arr.filter(event => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return eventDate.isSame(today, 'day') && eventDate.isSame(today, 'month') && eventDate.isSame(today, 'year');
        });
        break;
      case 'morgen':
        sortedByDates = arr.filter(event => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return eventDate.isSame(tomorrow, 'day') && eventDate.isSame(tomorrow, 'month') && eventDate.isSame(tomorrow, 'year');
        });
        break;
      case 'deze week':
        sortedByDates = arr.filter(event => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return eventDate.isAfter(startOfWeek) && eventDate.isBefore(endOfWeek) || eventDate.isSame(startOfWeek) || eventDate.isSame(endOfWeek);
        });
        break;
      case 'volgende week':
        sortedByDates = arr.filter(event => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return eventDate.isAfter(startOfNextWeek) && eventDate.isBefore(endOfNextWeek) || eventDate.isSame(startOfNextWeek) || eventDate.isSame(endOfNextWeek);
        });
        break;
      case 'deze maand':
        sortedByDates = arr.filter(event => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return eventDate.isAfter(startOfMonth) && eventDate.isBefore(endOfMonth) || eventDate.isSame(startOfMonth) || eventDate.isSame(endOfMonth);
        });
        break;
      default:
        sortedByDates = arr;
        break;
    }
    return sortedByDates;
  }

  const sortByCategory = (arr) => {
    let sortedByCategory = [];
    const sortStatus = sortState['categorie'].toLowerCase();
    switch (sortStatus) {
      case 'dag event':
        sortedByCategory = arr.filter(event => event.timeofday.toLowerCase() === 'dag event');
        break;
      case 'nacht event':
        sortedByCategory = arr.filter(event => event.timeofday.toLowerCase() === 'nacht event');
        break;
      case 'festival':
        sortedByCategory = arr.filter(event => event.timeofday.toLowerCase() === 'festival');
        break;
      default:
        sortedByCategory = arr;
        break;
    }

    return sortedByCategory;
  }

  const sortByGenres = (arr) => {
    let sortedByGenres = [];
    const sortStatus = sortState['genre']?.map(genre => genre.toLowerCase());; // array

    if (!sortStatus || sortStatus.length === 0) {
      return [];
    } else if (sortStatus.length === 0) {
      return []
    }

    sortedByGenres = arr.filter(event => {
      const eventGenres = event.GenreList.split(',').map(genre => genre.trim().toLowerCase());
      return eventGenres.some(selectedGenre => sortStatus.includes(selectedGenre.toLowerCase()));
    });

    return sortedByGenres;
  }

  const sortByAge = (arr) => {
    let sortedByAge = [];
    const sortStatus = sortState['leeftijd'];

    switch (sortStatus) {
      case '18+':
        sortedByAge = arr;
        break;
      case '21+':
        sortedByAge = arr.filter(event => +event.MinimalAge >= 21);
        break;
      case '23+':
        sortedByAge = arr.filter(event => +event.MinimalAge >= 23);
        break;
      default:
        sortedByAge = arr;
        break;
    }
    return sortedByAge;
  }

  const sortByCity = (arr) => {
    let sortedByCity = [];
    const sortStatus = sortState['city'];

    switch (sortStatus) {
      case 'Amsterdam':
        sortedByCity = arr.filter(event => event.CityName.includes('Amsterdam'));
        break;

      case 'Rotterdam':
        sortedByCity = arr.filter(event => event.CityName.includes('Rotterdam'));
        break;
      default:
        sortedByCity = arr.filter(event => event.CityName.includes('Amsterdam') || event.CityName.includes('Rotterdam'));

        break;
    }
    return sortedByCity;
  }

  const handleTabClick = (tabNumber) => {
    console.log('tabNumber', tabNumber)
    // setEventsLoading(true);
    setTabLoading(true)
    setSortState((prevState) => ({
      datum: 'Alle Datums',
      categorie: 'Alle events',
      genre: [
        ...genres
      ],
      leeftijd: 'Alle leeftijden',
      city: tabNumber === 1 ? 'Amsterdam' : 'Rotterdam',
    }));
    setCurrentCity(tabNumber === 1 ? 'Amsterdam' : 'Rotterdam');
    setTab(tabNumber);
    sortAll();
    // will remove preloader when data is filtered
    
    setTimeout(() => {
      setTabLoading(false)
    //   setEventsLoading(false);
    }, 500);

  };

  if (eventData.length < 1) {
    return (
      <>
        <div className="hero-section" style={{ minHeight: '' }}>
          <div className="custom-container">
            <Logo />
            <div className="hero-tabs">
              <div
                className={clsx(
                  'hero-content tab-content tab',
                  tab === 1 ? 'block  tab-active' : 'hidden'
                )}
                data-id="tab1"
              >
                <h1 className="text-white text-center" id="heading">
                  Gastenlijst Amsterdam
                </h1>
              </div>

              <div
                className={clsx(
                  'hero-content tab-content tab',
                  tab === 2 ? 'block  tab-active' : 'hidden'
                )}
                data-id="tab2"
              >
                <h1 className="text-white text-center" id="heading">
                  Gastenlijst Rotterdam
                </h1>
              </div>
            </div>

            <div className="hero-search-bar">
              <SearchBar eventData={eventData} />
              <div className="tabs">
                <div className="row justify-content-between">
                  <div
                    className={clsx(
                      'amsterdam-tab cursor-pointer',
                      tab === 1 ? 'active' : ''
                    )}
                  >
                    <div
                      className={clsx('tab-a', tab === 1 ? 'active-a' : '')}
                      data-id="tab1"
                      onClick={() => handleTabClick(1)}
                    >
                      <img
                        src={AmsterdamIcon}
                        alt="amsterdam"
                        className="amsterdam"
                      />
                      <h5 className="text-white">Amsterdam</h5>
                    </div>
                  </div>

                  <div
                    className={clsx(
                      'rotterdam-tab cursor-pointer',
                      tab === 2 ? 'active' : ''
                    )}
                  >
                    <div
                      className={clsx('tab-a', tab === 2 ? 'active-a' : '')}
                      data-id="tab2"
                      onClick={() => handleTabClick(2)}
                    >
                      <img
                        src={RotterdamIcon}
                        alt="roterdam"
                        className="Rotterdam"
                      />
                      <h5 className="text-white">Rotterdam</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <section>
            <DiscoverEvent sortState={sortState} updateSortState={updateSortState} token={token} city={currentCity} genres={genres} />
          </section>
        </div>
      </>
    )
  }

  const showOnlineEventsSection = onlineEvents.length > 0;

  return (
    <div className="">
      <div className="hero-section">
        <div className="custom-container">
          <Logo />
          <div className="hero-tabs">
            <div
              className={clsx(
                'hero-content tab-content tab',
                tab === 1 ? 'block  tab-active' : 'hidden'
              )}
              data-id="tab1"
            >
              <h1 className="text-white text-center" id="heading">
                Gastenlijst Amsterdam
              </h1>
            </div>

            <div
              className={clsx(
                'hero-content tab-content tab',
                tab === 2 ? 'block  tab-active' : 'hidden'
              )}
              data-id="tab2"
            >
              <h1 className="text-white text-center" id="heading">
                Gastenlijst Rotterdam
              </h1>
            </div>
          </div>

          <div className="hero-search-bar">
            <SearchBar eventData={eventData} formattedCurrentDate={formattedCurrentDate} />
            <div className="tabs">
              <div className="row tabss ">
                <div
                  className={clsx(
                    'amsterdam-tab cursor-pointer',
                    tab === 1 ? 'active' : ''
                  )}
                >
                  <div
                    className={clsx('tab-a', tab === 1 ? 'active-a' : '')}
                    data-id="tab1"
                    onClick={() => handleTabClick(1)}
                  >
                    <img
                      src={AmsterdamIcon}
                      alt="amsterdam"
                      className="amsterdam"
                    />
                    <h5 className="text-white">Amsterdam</h5>
                  </div>
                </div>

                <div
                  className={clsx(
                    'rotterdam-tab cursor-pointer',
                    tab === 2 ? 'active' : ''
                  )}
                >
                  <div
                    className={clsx('tab-a', tab === 2 ? 'active-a' : '')}
                    data-id="tab2"
                    onClick={() => handleTabClick(2)}
                  >
                    <img
                      src={RotterdamIcon}
                      alt="roterdam"
                      className="Rotterdam"
                    />
                    <h5 className="text-white">Rotterdam</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* Ontdek envenment */}
      <section>
        <DiscoverEvent sortState={sortState} updateSortState={updateSortState} token={token} city={currentCity} genres={genres} />
      </section>

      {/*  */}

      <section className="zoek-evenementen">
        {(eventsLoading || tabLoading) ? (
          <div className="custom-container">

            <EventItemPreloader />

            {/* {isDivider && ( */}
            <div className="card-divider"></div>
            {/* )} */}
          </div>
        ) : sortedData.length > 0 ? (
          <EventsList eventsData={sortedData} currentCity={currentCity} isDivider={showOnlineEventsSection} formattedCurrentDate={formattedCurrentDate} />
        ) : (
          <NoEvents />
        )}

        {/* <NoEvents /> */}

      </section>

      {showOnlineEventsSection && (
        <>
          {/* <div className="card-divider"></div> */}
          <section>
            <div className="custom-container">
              <h2 className="binnenkort">Binnenkort online</h2>
              <p className="text-white text-p">
                Zorg ervoor dat jij er als eerst bij bent!
              </p>
            </div>
          </section>
        </>
      )}
      {showOnlineEventsSection && (
        <section className="mb-[44px]">
          <Carousel onlineEvents={onlineEvents} city={currentCity} formattedCurrentDate={formattedCurrentDate} />
        </section>

      )}

    </div>
  );
};
export default Home;
