import EventCard from "./EventCard";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/nl';
import EventCardPreloader from "./EventCardPreloader";

dayjs.extend(utc); 
dayjs.extend(timezone); 

const preloaderData = [
  {
      "EventId": 33335,
      "EvenName": "SUPERFUTURE",
      "Trending": "No",
      "EvenementImages": "https://staging3.22night.com/wp-content/uploads/2023/11/Superfuture-Banner-2048-x-2048-px.png",
      "onlineSoonImage": "https://staging3.22night.com/wp-content/uploads/2023/11/Superfuture-Banner-2048-x-2048-px.png",
      "OriginalPrice": "15",
      "OurPrice": "10",
      "TheClub": "Jimmy Woo",
      "Coupon_code": "",
      "Description": "",
      "EventDate": "1700262000",
      "CityName": "Amsterdam",
      "RegistrationFrom": "1700218800",
      "RegistrationUntil": "1700262000",
      "MinimalAge": "21",
      "EventCategorie": "Gastenlijst",
      "TicketLink": "",
      "OutofStock": {
          "sold-out": "false"
      },
      "WhatsappLink": "",
      "StelzLink": "",
      "dporiginal": "",
      "timeofday": "Nacht event",
      "GenreList": "Hip-Hop, Hitjes, RnB",
      "Towns": "",
      "fulldate": {
          "date": "17 november",
          "day": "vrijdag"
      },
      "timestamp": 1700262000
  },
  {
      "EventId": 33334,
      "EvenName": "MAYHEM",
      "Trending": "Yes",
      "EvenementImages": "https://staging3.22night.com/wp-content/uploads/2023/11/Mayhem-Banner.png",
      "onlineSoonImage": "https://staging3.22night.com/wp-content/uploads/2023/11/Mayhem-Banner.png",
      "OriginalPrice": "15",
      "OurPrice": "10",
      "TheClub": "Supperclub",
      "Coupon_code": "",
      "Description": "Friends Guestlist | €7,50 in plaats van €15 | Urban, Afro & House ",
      "EventDate": "1700262000",
      "CityName": "Amsterdam",
      "RegistrationFrom": "1700218800",
      "RegistrationUntil": "1700262000",
      "MinimalAge": "21",
      "EventCategorie": "Gastenlijst",
      "TicketLink": "",
      "OutofStock": {
          "sold-out": "false"
      },
      "WhatsappLink": "",
      "StelzLink": "",
      "dporiginal": "29384",
      "timeofday": "Nacht event",
      "GenreList": "Classics, Hitjes, RnB",
      "Towns": "",
      "fulldate": {
          "date": "17 november",
          "day": "vrijdag"
      },
      "timestamp": 1700262000
  },
  {
      "EventId": 33333,
      "EvenName": "GURL",
      "Trending": "Yes",
      "EvenementImages": "https://staging3.22night.com/wp-content/uploads/2023/11/Gurl-Banner-g.png",
      "onlineSoonImage": "https://staging3.22night.com/wp-content/uploads/2023/11/Gurl-Banner-g.png",
      "OriginalPrice": "15",
      "OurPrice": "10",
      "TheClub": "Oliva",
      "Coupon_code": "",
      "Description": "",
      "EventDate": "1700262000",
      "CityName": "Amsterdam",
      "RegistrationFrom": "1700218800",
      "RegistrationUntil": "1700262000",
      "MinimalAge": "21",
      "EventCategorie": "Gastenlijst",
      "TicketLink": "",
      "OutofStock": {
          "sold-out": "false"
      },
      "WhatsappLink": "",
      "StelzLink": "",
      "dporiginal": "",
      "timeofday": "Nacht event",
      "GenreList": "Hip-Hop, Hitjes, RnB",
      "Towns": "",
      "fulldate": {
          "date": "17 november",
          "day": "vrijdag"
      },
      "timestamp": 1700262000
  },
  {
      "EventId": 33332,
      "EvenName": "GEWEIGERD",
      "Trending": "Yes",
      "EvenementImages": "https://staging3.22night.com/wp-content/uploads/2023/11/Geweigerd-Banner.png",
      "onlineSoonImage": "https://staging3.22night.com/wp-content/uploads/2023/11/Geweigerd-Banner.png",
      "OriginalPrice": "10",
      "OurPrice": "5",
      "TheClub": "Disco Dolly",
      "Coupon_code": "",
      "Description": "",
      "EventDate": "1700262000",
      "CityName": "Amsterdam",
      "RegistrationFrom": "1700218800",
      "RegistrationUntil": "1700780400",
      "MinimalAge": "18",
      "EventCategorie": "Gastenlijst",
      "TicketLink": "",
      "OutofStock": {
          "sold-out": "false"
      },
      "WhatsappLink": "",
      "StelzLink": "",
      "dporiginal": "",
      "timeofday": "Nacht event",
      "GenreList": "Classics, Hitjes, House",
      "Towns": "",
      "fulldate": {
          "date": "17 november",
          "day": "vrijdag"
      },
      "timestamp": 1700262000
  },
  {
      "EventId": 33331,
      "EvenName": "THE HUB",
      "Trending": "Yes",
      "EvenementImages": "https://staging3.22night.com/wp-content/uploads/2023/11/The-Hub-Banner.png",
      "onlineSoonImage": "https://staging3.22night.com/wp-content/uploads/2023/11/The-Hub-Banner.png",
      "OriginalPrice": "15",
      "OurPrice": "10",
      "TheClub": "Chicago Social Club",
      "Coupon_code": "",
      "Description": "",
      "EventDate": "1700262000",
      "CityName": "Amsterdam",
      "RegistrationFrom": "1700218800",
      "RegistrationUntil": "1700265540",
      "MinimalAge": "21",
      "EventCategorie": "Community ticket",
      "TicketLink": "https://app.celebratix.io/discover/24?code=gastams",
      "OutofStock": {
          "sold-out": "false"
      },
      "WhatsappLink": "",
      "StelzLink": "",
      "dporiginal": "",
      "timeofday": "Nacht event",
      "GenreList": "Disco, House",
      "Towns": "",
      "fulldate": {
          "date": "17 november",
          "day": "vrijdag"
      },
      "timestamp": 1700262000
  }
]

const EventItemPreloader = () => {

  return (
    <div className="tab preloader-events">
        <div className="tab-header">
          <div className="row justify-content-between text-white">
            <div className="days">
              <h3 className="donderdag">Preloader</h3>
            </div>
            <div className="date">
              <h5 className="date">Date preloader</h5>
            </div>
          </div>
        </div>
        <div className="tab-body">
          <div className="event-box">
            <div className="row">
            {preloaderData.map(event => (
              <EventCardPreloader
                key={event.EventId} 
              />
            ))}
            </div>
          </div>
        </div>
      </div>
  )
}

export default EventItemPreloader;